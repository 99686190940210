import React from "react";
import WhyUsItem from "./components/WhyUsItem";
import WhyUsItem2 from "./components/WhyUsItem2";
import { useAppContext } from "../../../../../context/AppContext";
import Button from "../Button";
import { faLocationDot, faFileContract, faQuestion, faDollarSign, faHouse } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

function WhyUs() {
    const { setUnderlinePosition, navIndex, setActiveSection } = useAppContext();
    const navigate = useNavigate();

    const handleContactClick = () => {
        setActiveSection("contact");
        setUnderlinePosition(navIndex * 112);
        navigate("/contact");
    };

    return (
        <div
            id="whyus"
            className=" flex flex-col h-screen overflow-hidden"
        >
            {/* TITLE CONTAINER */}
            <div className="flex justify-center items-end h-[15vh] md:h-[10vh] "></div>

            {/* SERVICE ITEMS CONTAINER - NO PY*/}
            <div className="flex flex-1 items-center px-[12vw] sm:px-[14vw] md:px-[10vw] lg:px-[12vw] xl:px-[14vw]">
                {/* FLEX CONTAINER */}
                <div className="flex flex-col md:flex-row gap-x-[10vw] gap-y-[3vh]">
                    <WhyUsItem
                        icon={faQuestion}
                        title="Why Choose Us"
                        description="For the past decade, Greens Garbage Grabbers have provided services to the community of Springbank.
                                     Over this time we have become aware of the challenges unique to Springbank and have focused our 
                                     operations towards helping solve these challenges. We take pride in our community and are motivated
                                     to continue to provide amazing services to our friends and neighbours."
                    />
                    <div className="flex flex-1 flex-col gap-y-[1vh] md:gap-y-[2vh] justify-between">
                        <WhyUsItem2 icon={faFileContract} text="Contract Free" />
                        <WhyUsItem2 icon={faHouse} text="Right to your door" />
                        <WhyUsItem2 icon={faDollarSign} text="Flexible Pricing" />
                        <WhyUsItem2 icon={faLocationDot} text="Locally Owned" />
                    </div>
                </div>
            </div>

            {/* BUTTON CONTAINER */}
            <div className="flex h-[15vh] md:h-[20vh] justify-center">
                {/* FLEX CONTAINER */}
                <div className="flex flex-1 gap-x-[4vw] md:gap-x-[2vw] justify-center items-center mb-[0vh] sm:mb-[2vh] lg:mt-[3vh] lg:items-start">
                    <Button text="contact" onClick={handleContactClick} />

                </div>
            </div>
        </div>
    );
}

export default WhyUs;
