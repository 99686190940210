import Button from "../Button";
import { useAppContext } from "../../../../../context/AppContext";
import { useNavigate } from "react-router-dom";

function Hero() {
    const { setUnderlinePosition, navIndex, setActiveSection } =
        useAppContext();
    const navigate = useNavigate();

    const handleViewServices = () => {
        const serviceSection = document.getElementById("services");
        if (serviceSection) {
            serviceSection.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleContactClick = () => {
        setActiveSection("contact");
        setUnderlinePosition(navIndex * 112);
        navigate("/contact");
    };

    return (
        <div
            id="hero"
            className="flex flex-col h-screen justify-center items-center"
        >
            <div className="flex w-full justify-center items-end h-[10vh] md:h-[20vh] "></div>

            <div className="flex flex-col flex-1 items-center justify-center gap-y-[4vh] px-[10vw] md:px-0">
                {/* TITLE */}
                <div className="flex text-white font-bold tracking-wider text-center text-[40px] md:text-[48px] lg:text-[64px] 2k:text-[64px]">
                    Greens Garbage Grabbers
                </div>
                {/* TITLE */}
                <div className="flex text-neutral-300 font-bold tracking-wider text-center text-[18px] md:text-[20px] lg:text-[22px] 2k:text-[24px]">
                    RESIDENTIAL WASTE & RECYLING PICKUP SERVICES
                </div>
            </div>
            {/* BUTTONS */}
            <div className="flex w-full flex-col md:flex-row md:justify-center items-center md:items-start h-[30vh] md:h-[30vh] gap-y-[2vh] md:gap-x-[4vw]">
                <Button text="view services" onClick={handleViewServices} />
                <Button text="contact us" onClick={handleContactClick} />
            </div>
        </div>
    );
}

export default Hero;
