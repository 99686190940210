import Hero from "./components/Hero/Hero";
import Section1 from "./components/Services/Services";
import Section2 from "./components/WhyUs/WhyUs";
import Section3 from "./components/AboutUs/AboutUs";
import Footer from "./components/Footer/Footer";

function AppContent() {
    return (
        <div className="flex flex-col bg-blue-950 items-center justify-center relative overflow-hidden">
            {/* <div className="rounded-[50%] opacity-100 brightness-[1] blur-[70px] z-5 bg absolute top-[7%] left-[10%] w-[1600px] h-[350px] rotate-[0deg] bg-blue-800"></div> */}
            <div className="rounded-[50%] opacity-100 brightness-[0.7] blur-[100px] z-5 bg absolute top-[7%] left-[-7%] w-[2000px] h-[500px] rotate-[25deg] bg-blue-800"></div>


            <div className="flex flex-col bg-black relative bg-opacity-75">
                <Hero />
                <Section1 />
                <Section2 />
                <Section3 />
                <Footer />
            </div>
        </div>
    );
}

export default AppContent;
