import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppProvider } from "./context/AppContext";
import AppWrapper from "./AppWrapper/AppWrapper";
import AppContent from "./AppWrapper/components/AppContent/AppContent";
import Contact from "./AppWrapper/components/Contact/Contact";

function App() {
    return (
        <Router>
            <AppProvider>
                <Routes>
                    <Route path="/" element={<AppWrapper />}>
                        <Route index element={<AppContent />} />
                        <Route path="/contact" element={<Contact />} />
                    </Route>
                </Routes>
            </AppProvider>
        </Router>
    );
}

export default App;
