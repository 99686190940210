import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ItemProps {
    icon: IconProp; // Assuming `icon` is an icon definition from FontAwesome
    title: string; // `title` is a string
    description: string; // `description` is a string
    BlurRotate: string;
    BlurAnchor: string;
}

function WhyUsItem({
    icon,
    title,
    description,
    BlurRotate,
    BlurAnchor,
}: ItemProps) {
    return (
        <div className="rounded-xl md:rounded-2xl flex flex-1 flex-col overflow-hidden p-[2px] relative">
            {/* GRADIENT BORDER BACKGROUND */}
            <div className="absolute w-[25%] h-[250%] left-[40%] top-[-75%] blur-[60px] bg-blue-800 animate-spin"></div>

            {/* BG + BACKDROP CONTAINER */}
            <div className="rounded-xl md:rounded-2xl flex flex-1 flex-col relative overflow-hidden bg-blue-950">
                {/* CIRCLE BLUR */}
                <div
                    className={`radius-[50%] opacity-100 blur-[50px] z-5 bg absolute top-[25%] w-[400px] h-[50px] bg-blue-700`}
                    style={{ rotate: `${BlurRotate}`, [BlurAnchor]: `5%` }}
                ></div>

                {/* CONTENT CONTAINER */}
                <div className="rounded-xl md:rounded-2xl flex flex-1 z-10 flex-col bg-black/60 px-[2vw] md:px-[1vw] py-[2vh] gap-y-[1vh] border border-blue-900/50">
                    {/* TITLE-ICON + UNDERLINE CONTAINER */}
                    <div className="flex flex-col gap-y-[2vh] px-[2vw] md:px-[1vw]">
                        {/* TITLE + ICON CONTAINER */}
                        <div className="flex z-10 items-center gap-x-[2vw] md:gap-x-[1vw] md:gap-y-[2vh]">
                            {/* ICON */}
                            <div className="flex bg-white text-blue-950 items-center justify-center rounded-xl w-10 h-10 md:w-12 md:h-12 2k:w-14 2k:h-14 text-[20px] md:text-[24px] lg:text-[26px] 2k:text-[30px]">
                                <FontAwesomeIcon icon={icon} />
                            </div>
                            {/* TITLE */}
                            <div className="flex flex-1">
                                <div className="text-white font-bold tracking-wider text-[20px] md:text-[24px] lg:text-[26px] 2k:text-[30px] truncate">
                                    {title}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* DESCRIPTION CONTAINER */}
                    <div className="flex flex-1 z-10 md:pt-[1vh] px-[2vw] md:px-[1vw] text-[15px] md:text-[18px] lg:text-[18px] 2k:text-[20px] tracking-wide leading-6 md:leading-7 2k:leading-8 ">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUsItem;
