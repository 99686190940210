import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Underline from "../../AppContent/components/Underline";

function WhyUsItem() {
    return (
        <div className="rounded-xl md:rounded-2xl flex flex-col p-[2px] relative">
            {/* BORDER SHADOW */}
            <div className="rounded-xl md:rounded-2xl absolute z-5 w-full h-full top-0 left-0 blur-[3px] md:blur-[3px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            {/* BG + BACKDROP CONTAINER */}
            <div className="flex flex-col flex-1 relative rounded-xl md:rounded-2xl overflow-hidden bg-blue-950">
                {/* CIRCLE BLUR */}
                <div className="radius-[50%] opacity-100 blur-[50px] z-5 bg absolute top-[25%] w-[400px] h-[50px] bg-blue-700"></div>

                {/* CONTENT CONTAINER */}
                <div className="flex flex-1 z-10 flex-col rounded-xl md:rounded-2xl bg-black/60 items-center border border-blue-900/50 px-[1vw] py-[3vh] md:px-[4vw] md:py-[8vh] gap-y-[3vh] md:gap-y-[6vh]">
                    <div className="flex flex-col gap-y-[2vh] md:gap-y-[4vh]">
                        {/* TITLE + UNDERLINE CONTAINER */}
                        <div>
                            {/* TITLE CONTAINER */}
                            <div className="flex text-white font-bold tracking-wider text-[24px] md:text-[28px]">
                                Give Us A Call
                            </div>

                            {/* UNDERLINE ROW CONTAINER */}
                            <div className="flex w-full">
                                <Underline triggerPoint={1} />
                            </div>
                        </div>

                        {/* INFO CONTAINER */}
                        <div className="flex flex-1 flex-col gap-y-[2vh] md:gap-y-[4vh]">
                            {/* MIKE CONTAINER */}
                            <div className="flex flex-col gap-y-[1vh] md:gap-y-[2vh]">
                                {/* NAME */}
                                <div className="flex text-white font-bold tracking-wider text-[18px] md:text-[22px]">
                                    Mike
                                </div>
                                {/* ICON + PHONE ROW */}
                                <div className="flex gap-x-3 items-center tracking-wider text-[16px] md:text-[20px]">
                                    <FontAwesomeIcon icon={faPhone} />
                                    <div className="text-[14px] md:text-[16px] nunito">
                                        (403) 667-1426
                                    </div>
                                </div>
                            </div>

                            {/* STACY CONTAINER */}
                            <div className="flex flex-col gap-y-[1vh] md:gap-y-[2vh]">
                                {/* NAME */}
                                <div className="flex text-white font-bold tracking-wider text-[18px] md:text-[22px]">
                                    Stacy
                                </div>
                                {/* ICON + PHONE ROW */}
                                <div className="flex gap-x-3 items-center tracking-wider text-[16px] md:text-[20px]">
                                    <FontAwesomeIcon icon={faPhone} />
                                    <div className="text-[14px] md:text-[16px] nunito">
                                        (403) 667-1427
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUsItem;
