import ContactItem from "./components/ContactItem";
import Faq from "./components/Faq";

function Contact() {
    return (
        <div className="flex flex-col bg-blue-950 md:h-screen relative overflow-hidden">
            {/* CIRCLE BLUR */}
            <div className="rounded-[50%] opacity-100 brightness-[0.7] blur-[100px] z-5 bg absolute top-[30%] left-[-7%] w-[2000px] h-[500px] rotate-[25deg] bg-blue-800"></div>

            {/* BG-BLACK CONTAINER */}
            <div className="flex flex-1 flex-col bg-black/75 relative">
                {/* TITLE */}
                <div className="flex w-full h-[10vh] md:h-[20vh]"></div>

                {/* CONTENT */}
                <div className="flex flex-1 flex-col md:flex-row gap-y-[5vh] gap-x-[8vw] justify-center py-[5vh] px-[12vw] md:px-[20vh]">
                    <ContactItem />
                    <Faq />

                </div>

                {/* SPACER */}
                <div className="flex w-full md:h-[25vh] gap-x-8"></div>
            </div>
        </div>
    );
}

export default Contact;
