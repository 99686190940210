import React, { createContext, useContext, useMemo, useState, ReactNode } from "react";

const AppContext = createContext<{
    setUnderlinePosition: React.Dispatch<React.SetStateAction<number>>;
    underlinePosition: number;
    navIndex: number;
    setActiveSection: React.Dispatch<React.SetStateAction<string | null>>;
    activeSection: string | null;
  }>({
    setUnderlinePosition: () => {},
    underlinePosition: 0,
    navIndex: 0,
    setActiveSection: () => {},
    activeSection: null,
  });

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }: { children: ReactNode }) => {
    const [activeSection, setActiveSection] = useState<string | null>(null);
    const [underlinePosition, setUnderlinePosition] = useState(0);
    const buttons = ["services", "about", "contact"];
    const navIndex = buttons.indexOf("contact");

    const memoizedContextValue = useMemo(
        () => ({
          setActiveSection,
          activeSection,
          underlinePosition,
          setUnderlinePosition,
          navIndex,
        }),
        [activeSection, underlinePosition, setUnderlinePosition, navIndex]
      );

    return (
        <AppContext.Provider value={memoizedContextValue}>
            {children}
        </AppContext.Provider>
    );
};