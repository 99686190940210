import {
    faChevronDown,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

function Faq() {
    const [openQuestionId, setOpenQuestionId] = useState<
        number | string | null
    >(null);

    function toggleDropdown(questionId: number | string) {
        setOpenQuestionId((prevOpenQuestionId) =>
            prevOpenQuestionId === questionId ? null : questionId
        );
    }

    const questions = [
        {
            id: 1,
            question: "What items can I dispose through your service?",
            answer: "We accept household waste, including kitchen and bathroom waste, as well as recyclable materials such as paper, cardboard, plastics, cans. Contact for specific items or materials.",
        },
        {
            id: 2,
            question: "Do you handle hazardous waste disposal?",
            answer: "We do not handle hazardous waste as part of our service. However, we can provide insights on proper disposal methods or refer you to appropriate facilities for disposal.",
        },
        {
            id: 3,
            question: "What happens if I have extra waste on occasion?",
            answer: "If you have extra waste beyond your usual pickup volume, you can arrange for an additional pickup for a small fee. Just let us know in advance, and we'll take care of it.",
        },
        {
            id: 4,
            question: "Do we need to sign a contract to get started?",
            answer: "No, there's no need to get hung up in long-term contracts. You can start our services anytime and have the freedom to stop or pause whenever needed.",
        },

        {
            id: 5,
            question:
                "Do we need to bring our bins to the end of the driveway?",
            answer: "No, we come right to your door to collect your waste and recycling. No need to worry about hauling heavy bins to the end of your driveway.",
        },
    ];

    return (
        <div className="rounded-xl md:rounded-2xl flex md:w-[777px] flex-col p-[2px] relative">
            {/* BORDER SHADOW */}
            <div className="rounded-xl md:rounded-2xl absolute z-5 w-full h-full top-0 left-0 blur-[3px] md:blur-[3px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            {/* BG + BACKDROP CONTAINER */}
            <div className="flex flex-1 flex-col relative rounded-xl md:rounded-2xl overflow-hidden bg-blue-950">
                {/* CIRCLE BLUR */}
                <div className="radius-[50%] opacity-100 blur-[50px] z-5 bg absolute top-[25%] w-[400px] h-[50px] bg-blue-700"></div>

                {/* CONTENT CONTAINER */}
                <div className="rounded-xl md:rounded-2xl flex flex-1 z-10 flex-col bg-black/60 px-[3vw] md:px-[2vw] py-[3vh] gap-y-[3vh] border border-blue-900/50 overflow-y-auto">
                    {/* TITLE */}
                    <div className="text-white justify-center flex font-bold tracking-wider text-[28px]">
                        FAQ
                    </div>

                    {/* INFO CONTAINER */}
                    <div className="flex flex-1 flex-col gap-y-[2vh] md:gap-y-[3vh]">
                        {questions.map((q) => (
                            <div
                                key={q.id}
                                className={`flex flex-col gap-y-[1vh] bg-blue-900/25 rounded-lg border border-blue-900/50 px-4 py-2 relative
                                ${
                                    openQuestionId === q.id
                                        ? "rounded-b-none"
                                        : ""
                                }`}
                            >
                                <div
                                    onClick={() => toggleDropdown(q.id)}
                                    className="flex gap-x-4 items-center cursor-pointer"
                                >
                                    <div className="font-bold">
                                        {openQuestionId === q.id ? (
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                            />
                                        ) : (
                                            <FontAwesomeIcon
                                                icon={faChevronRight}
                                            />
                                        )}
                                    </div>
                                    <div className="flex flex-1 font-bold mb-[1px]">
                                        {q.question}
                                    </div>
                                </div>
                                {openQuestionId === q.id && (
                                    <div className="mt-[8px] text-white z-10 transition-all duration-300 ease-in-out absolute top-full left-0 right-0 bg-blue-900/100 rounded-b-2xl overflow-hidden border border-blue-800/50">
                                        <div className="bg-black/50 px-4 py-4">
                                            {q.answer}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Faq;
