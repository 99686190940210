import React from "react";
import AboutUsItem from "./components/AboutUsItem";
import AboutUsItem2 from "./components/AboutUsItem2";
import { useAppContext } from "../../../../../context/AppContext";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

function AboutUs() {
    const navigate = useNavigate();
    const { setUnderlinePosition, navIndex, setActiveSection } = useAppContext();

    const handleContactClick = () => {
        setActiveSection("contact");
        setUnderlinePosition(navIndex * 112);
        navigate("/contact");
    };

    return (
        <div id="about" className=" flex flex-col h-screen overflow-hidden">
            {/* TITLE CONTAINER */}
            <div className="flex justify-center items-end h-[15vh] md:h-[15vh] "></div>

            {/* SERVICE ITEMS CONTAINER - NO PY*/}
            <div className="flex flex-1 items-center px-[10vw] sm:px-[14vw] md:px-[10vw] lg:px-[12vw] xl:px-[16vw]">
                {/* FLEX CONTAINER */}
                <div className="flex flex-col md:flex-row md:gap-x-[8vw] lg:gap-x-[12vw] xl:gap-x-[14vw] 2k:gap-x-[18vw] gap-y-[2vh]">
                    {/* OWNER INFO */}
                    <AboutUsItem
                        icon={faQuestion}
                        title="Meet The Owners"
                        description="Hi! My name is Stacy, our family moved to Springbank in 1978. In 2008, my husband,
                                     Mike, and I bought this Company from the man who started it all and has been amazing
                                     ever since. We are a small family run company and love being involved with and getting 
                                     to know our customers in hopes to provide even better, more accommodatable services."
                    />
                    {/* OWNER PHOTO */}
                    <AboutUsItem2 />
                </div>
            </div>

            {/* BUTTON CONTAINER */}
            <div className="flex h-[15vh] md:h-[20vh] justify-center">
                {/* FLEX CONTAINER */}
                <div className="flex flex-1 gap-x-[4vw] md:gap-x-[2vw] justify-center items-center mb-[2vh] sm:mb-[3vh] lg:mt-[4vh] lg:items-start">
                    <Button text="contact" onClick={handleContactClick} />
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
