import React, { useEffect, useRef, useState } from "react";

interface ItemProps {
    triggerPoint: number;
  }
  
  function Underline({ triggerPoint }: ItemProps) {
    const underlineRef = useRef(null);
    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (
                        entry.isIntersecting &&
                        entry.intersectionRatio >= triggerPoint
                    ) {
                        setIsAnimating(true);
                    } else {
                        setIsAnimating(false);
                    }
                });
            },
            { threshold: triggerPoint }
        );

        const currentUnderlineRef = underlineRef.current;
        if (currentUnderlineRef) {
            observer.observe(currentUnderlineRef);
        }

        return () => {
            if (currentUnderlineRef) {
                observer.unobserve(currentUnderlineRef);
            }
        };
    }, [triggerPoint]);

    const underlineStyle = {
        height: "4px",
        width: "100%",
        backgroundColor: "white",
        borderRadius: "4px",
        transformOrigin: "left",
        transform: isAnimating ? "scaleX(1)" : "scaleX(0)",
        transition: "transform 0.5s ease-in-out",
    };

    return <div className="hidden md:block" ref={underlineRef} style={underlineStyle}></div>;
}

export default React.memo(Underline);
