import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ItemProps {
    icon: IconProp; // Assuming `icon` is an icon definition from FontAwesome
    text: string; // `title` is a string
}

function WhyUsItem2({ icon, text }: ItemProps) {
    return (
        <div className="rounded-xl md:rounded-2xl flex justify-center relative">

            {/* BORDER SHADOW */}
            <div className="rounded-xl md:rounded-2xl absolute z-5 w-full h-full top-0 left-0 blur-[2px] md:blur-[3px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            {/* CONTENT CONTAINER */}
            <div className="rounded-xl md:rounded-2xl flex flex-1 items-center bg-blue-950/100 justify-center border border-blue-900/50 relative overflow-hidden">
                {/* CIRCLE BLUR */}
                <div className={`rounded-[50%] z-5 bg-blue-800/100 brightness-[1] blur-[50px] z-5 bg absolute w-[175px] h-[100px] md:w-[350px] md:h-[100px] left-[-5%]`}></div>

                {/* CONTENT CONTAINER */}
                <div className="rounded-xl md:rounded-2xl flex z-10 flex-1 h-full bg-black/60 items-center px-[2vw] md:px-[1vw] py-[0.75vh] md:py-[2vh] gap-x-[2vw] md:gap-x-[1vw]">
                    {/* ICON CONTAINER */}
                    <div className="flex w-6 h-6 md:h-9 md:w-9 2k:h-12 2k:w-12 rounded-md md:rounded-lg text-[14px] md:text-[20px] 2k:text-[24px] bg-white items-center justify-center text-blue-950/100 font-bold">
                        <FontAwesomeIcon
                            icon={icon}
                        />
                    </div>
                    <div className="flex text-white text-[14px] md:text-[20px] lg:text-[20px] 2k:text-[28px] mt-[2px] tracking-wide md:tracking-normal">
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyUsItem2;
