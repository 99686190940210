import React from "react";

function Footer() {
    return (
        <div className="flex h-[10vh] relative border-t border-blue-900/50 ">
            {/* BORDER SHADOW */}
            <div className="absolute z-5 w-full h-[10px] top-0 left-0 blur-[3px] md:blur-[7px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            <div className="flex flex-1 bg-blue-950/100 relative overflow-hidden">
                {/* CIRCLE BLUR */}
                <div className="rounded-[50%] opacity-100 brightness-[1] blur-[40px] z-5 bg absolute top-[-15%] left-[44.5%] w-[200px] h-[125px] rotate-[0deg] bg-blue-800"></div>
                {/* CONTENT CONTAINER */}

                <div className="flex flex-1  bg-black/65 z-10 items-center justify-center gap-x-[3vw] md:gap-x-[0.75vw]">
                    {/* MADE BY */}
                    <div className="flex justify-center items-center tracking-wider font-sourcecode text-[18px]">
                        GREENS GARBAGE GRABBERS
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Footer;
