import { useEffect, useState } from "react";
import Logo from "./Logo";
import { useAppContext } from "../../../context/AppContext";
import { useNavigate, useLocation } from "react-router-dom";

function NavBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);
    const [isNavClicked, setIsNavClicked] = useState(false);
    const { setUnderlinePosition, underlinePosition, navIndex, activeSection, setActiveSection } = useAppContext();

    // Add scroll event listener
    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset;
            setIsScrolled(scrollTop > 0);

            if (!isNavClicked && location.pathname === "/") {
                const sections = ["services", "about"];
                const scrollPosition = window.innerHeight * 0.5;

                if (scrollTop === 0) {
                    // Reset active section and underline position when scrolled to the top
                    setActiveSection(null);
                    setUnderlinePosition(0);
                } else {
                    for (let i = sections.length - 1; i >= 0; i--) {
                        const section = document.getElementById(sections[i]);
                        if (section && section.getBoundingClientRect().top < scrollPosition) {
                            setActiveSection(sections[i]);
                            setUnderlinePosition(i * 112);
                            break;
                        }
                    }
                }
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isNavClicked, location.pathname, setUnderlinePosition, setActiveSection]);

    // Set active section and underline position when route changes
    useEffect(() => {
        if (
          location.pathname === "/" &&
          activeSection === "contact"
        ) {
          setActiveSection(null);
          setTimeout(() => {
            // Delayed Reset underline position
            setUnderlinePosition(0);
        }, 500);
        }
      }, [location.pathname, activeSection, setActiveSection, setUnderlinePosition]);

    const handleNavClick = (section: string) => {
        // Set isNavClicked to true
        setIsNavClicked(true);
        // Set active button to the clicked button
        setActiveSection(section);
        const buttons = ["services", "about", "contact"];
        // Get the index of the clicked button
        const navIndex = buttons.indexOf(section);
        // Set underline position
        setUnderlinePosition(navIndex * 112);

        // Navigate to the home route ("/")
        navigate("/");
        setTimeout(() => {
            const element = document.getElementById(section);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
                setTimeout(() => {
                    setIsNavClicked(false);
                }, 600);
            }
        }, 100);
    };

    const handleLogoClick = () => {
        // Reset active button
        setActiveSection(null);
        // Reset isNavClicked
        setIsNavClicked(true);

        // Navigate to the home route ("/")
        navigate("/");
        // Scroll to the hero
        const heroElement = document.getElementById("hero");
        if (heroElement) {
            heroElement.scrollIntoView({ behavior: "smooth" });
        }
        setTimeout(() => {
            // Delayed Reset underline position
            setUnderlinePosition(0);
            // Delayed Reset isNavClicked
            setIsNavClicked(false);
        }, 500);
    };

    const handleContactClick = () => {
        setActiveSection("contact");
        setUnderlinePosition(navIndex * 112);
        navigate("/contact");
    };

    return (
        <div
            className={`fixed top-8 left-1/2 transform -translate-x-1/2 z-20 flex justify-around items-center w-8/12 h-16 rounded-xl backdrop-blur-md transition-all duration-500 py-8 px-10 ${
                isScrolled
                    ? "bg-blue-950/25 border-[1px] border-blue-900/50"
                    : "bg-transparent border-[1px] border-transparent"
            }`}
        >
            {/* Logo Wrap */}
            <div className="flex flex-1 h-16" onClick={() => handleLogoClick()}>
                <Logo />
            </div>

            {/* Button and Underline Wrap */}
            <div className="flex h-16 flex-col justify-center">
                {/* Button Wrap */}
                <div className="flex text-white tracking-wider text-[16px] font-bold h-8 items-end justify-end">
                    {/* Button 1*/}
                    <div
                        className={`hidden sm:block text-center w-28 hover:text-blue-700 ${
                            activeSection === "services"
                                ? "transition-all duration-600 text-blue-700"
                                : ""
                        }`}
                        onClick={() => handleNavClick("services")}
                    >
                        services
                    </div>

                    {/* Button 2 */}
                    <div
                        className={`hidden sm:block text-center w-28 hover:text-blue-700 ${
                            activeSection === "about"
                                ? "transition-all duration-600 text-blue-700"
                                : ""
                        }`}
                        onClick={() => handleNavClick("about")}
                    >
                        about us
                    </div>

                    {/* Button 3 */}
                    <div
                        className={`text-center w-28 hover:text-blue-700 mb-1 sm:mb-0${
                            activeSection === "contact"
                                ? "transition-all duration-600 text-blue-700"
                                : ""
                        }`}
                        onClick={handleContactClick}
                    >
                        contact
                    </div>
                </div>

                {/* Underline Wrap */}
                <div className="hidden sm:flex h-2 items-center">
                    {/* Underline */}
                    <div
                        className={`w-20 relative left-4 h-0.5 bg-blue-700 transition-all duration-500 ${
                            activeSection ? "opacity-100" : "opacity-0"
                        }`}
                        style={{
                            transform: `translateX(${underlinePosition}px)`,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;
