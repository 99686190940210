import OwnerIMG from "../../../../../../assets/Owner.jpg";

function AboutUsItem2() {

    return (
        <div className="flex flex-1 rounded-2xl justify-center border border-blue-900/50 relative">
            {/* BORDER SHADOW */}
            <div className="absolute z-5 rounded-2xl w-full h-full top-0 left-0 blur-[5px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            {/* CONTENT CONTAINER */}
            <div className="flex z-10 justify-center overflow-hidden rounded-2xl flex-col bg-blue-900/100">
                <div className="flex flex-1 z-10 items-center flex-col bg-black/70 ">
                    <img src={OwnerIMG} alt="joe" className="rounded-2xl brightness-[0.8]" style={{ height: "100%", width: "100%"}} />
                </div>
            </div>
        </div>
    );
}

export default AboutUsItem2;
