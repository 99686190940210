import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Underline from "../../Underline";

interface ItemProps {
    icon: IconProp; // Assuming `icon` is an icon definition from FontAwesome
    title: string; // `title` is a string
    description: string; // `description` is a string
}

function AboutUsItem({ icon, title, description }: ItemProps) {
    return (
        <div className="rounded-xl md:rounded-2xl flex flex-1 flex-col relative border border-blue-900/50 ">
            {/* BORDER SHADOW */}
            <div className="rounded-xl md:rounded-2xl absolute z-5 w-full h-full top-0 left-0 blur-[3px] md:blur-[5px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            {/* BG + BACKDROP CONTAINER */}
            <div className="flex flex-1 flex-col rounded-xl md:rounded-2xl relative overflow-hidden bg-blue-950/100">
                {/* CIRCLE BLUR */}
                <div className="rounded-[50%] opacity-100 brightness-[1] blur-[50px] z-5 bg absolute top-[50%] left-[-10%] w-[700px] h-[75px] rotate-[45deg] bg-blue-800"></div>

                {/* CONTENT CONTAINER */}
                <div className="flex flex-1 z-10 flex-col bg-black bg-opacity-60 px-[1vw] pt-[1.5vh] pb-[1.5vh] md:px-[1vw] md:pt-[3.5vh] md:pb-[2vh] gap-y-[1vh]">
                    {/* TITLE-ICON + UNDERLINE CONTAINER */}
                    <div className="flex flex-col gap-y-[1vh] md:gap-y-[2vh] px-[2vw] md:px-[1vw] ">
                        {/* TITLE + ICON CONTAINER */}
                        <div className="flex z-10 items-center gap-x-[4vw] sm:gap-x-[2vw] md:gap-x-[1vw] md:gap-y-[2vh]">
                            {/* ICON */}
                            <div className="flex bg-white text-blue-950 items-center justify-center rounded-xl w-10 h-10 md:w-12 md:h-12 2k:w-14 2k:h-14 text-[20px] md:text-[24px] lg:text-[26px] 2k:text-[30px]">
                                <FontAwesomeIcon icon={icon} />
                            </div>
                            <div className="flex flex-1">
                                <div className="text-white font-bold tracking-wider text-[20px] md:text-[24px] lg:text-[26px] 2k:text-[30px] truncate">
                                    {title}
                                </div>
                            </div>
                        </div>
                        {/* UNDERLINE ROW CONTAINER */}
                        <div className="flex flex-1">
                            <Underline triggerPoint={1} />
                        </div>
                    </div>

                    {/* DESCRIPTION CONTAINER */}
                    <div className="flex flex-1 z-10 md:pt-[1vh] px-[2vw] md:px-[1vw] text-[15px] md:text-[18px] lg:text-[18px] 2k:text-[20px] tracking-wide leading-6 md:leading-7 2k:leading-8 ">
                        {description}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUsItem;
