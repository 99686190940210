import ServicesItem from "./components/ServiceItem";
import { useAppContext } from "../../../../../context/AppContext";
import Underline from "../Underline";
import { faTrash, faRecycle } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { useNavigate } from "react-router-dom";

function Services() {
    const navigate = useNavigate();
    const { setUnderlinePosition, navIndex, setActiveSection } =
        useAppContext();

    const handleLearnMore = () => {
        const whyUsSection = document.getElementById("whyus");
        if (whyUsSection) {
            whyUsSection.scrollIntoView({ behavior: "smooth" });
        }
    };

    const handleContactClick = () => {
        setActiveSection("contact");
        setUnderlinePosition(navIndex * 112);
        navigate("/contact");
    };

    return (
        <div id="services" className=" flex flex-col h-screen overflow-hidden">
            {/* TITLE CONTAINER */}
            <div className="flex justify-center items-end h-[20vh] md:h-[25vh]">
                {/* FLEX CONTAINER */}
                <div className="flex flex-col items-center ">
                    {/* TITLE */}
                    <h1 className="text-white text-[24px] sm:text-[32px] md:text-[40px] 2k:text-[48px] font-bold">
                        services
                    </h1>
                    {/* Underline */}
                    <Underline triggerPoint={1} />
                </div>
            </div>

            {/* SERVICE ITEMS CONTAINER */}
            <div
                className="flex flex-1 items-center px-[8vw] sm:px-[14vw] md:px-[12vw] lg:px-[16vw] xl:px-[18vw] 2k:px-[20vw]

            "
            >
                {/* FLEX CONTAINER */}
                <div className="flex flex-1 flex-col md:flex-row gap-x-[10vw] gap-y-[4vh] ">
                    <ServicesItem
                        icon={faTrash}
                        title="Waste Pickup"
                        BlurRotate="135deg"
                        BlurAnchor="left"
                        description="Personalized routine waste pickup, directly to your
                                    door. We aim to take the hassle of waste management out
                                    of your lives by providing convenient and accomodable
                                    service for all households of Springbank."
                    />
                    <ServicesItem
                        icon={faRecycle}
                        title="Recycle Pickup"
                        BlurRotate="45deg"
                        BlurAnchor="right"
                        description="Contribute to better sustainability from the comfort of your home.
                         At Greens, ensuring your recyclables are responsibly managed and sent to the 
                         proper facilities is our priority. Your efforts should count!"
                    />
                </div>
            </div>

            {/* BUTTON CONTAINER */}
            <div className="flex h-[15vh] md:h-[20vh] justify-center">
                {/* FLEX CONTAINER */}
                <div className="flex flex-1 gap-x-[4vw] md:gap-x-[2vw] justify-center items-start mt-[3vh] sm:mt-[2vh] md:mt-0 md:items-start">
                    <Button text="learn more" onClick={handleLearnMore} />
                    <Button text="contact" onClick={handleContactClick} />
                </div>
            </div>
        </div>
    );
}

export default Services;
