interface ItemProps {
    text: string; // `title` is a string
    onClick: () => void;
}

function Button({ text, onClick }: ItemProps) {

    return (
        <div className="flex flex-col w-[175px] rounded-xl md:rounded-2xl relative border border-blue-900/50 transition-transform duration-300 hover:translate-y-[-10px]">
        {/* BORDER SHADOW */}
            <div className="rounded-xl md:rounded-2xl absolute z-5 w-full h-full top-0 left-0 blur-[2px] md:blur-[3px] brightness-[1] bg-blue-800/50 bg-cover bg-center"></div>

            {/* BG + BACKDROP CONTAINER */}
            <div className="flex flex-col rounded-xl md:rounded-2xl relative overflow-hidden bg-blue-950/100">
                {/* CIRCLE BLUR */}
                <div className="rounded-[50%] opacity-100 brightness-[1] blur-[15px] z-5 bg absolute top-[5%] right-[-50%] w-[200px] h-[20px] rotate-[160deg] bg-blue-800"></div>

                {/* CONTENT CONTAINER */}
                <div className="flex z-10 flex-col bg-black bg-opacity-50">

                    {/* BUTTON CONTAINER */}
                    <div 
                    onClick = {onClick}
                    className="flex justify-center py-3 flex-1 z-10 font-bold font-sourcecode text-[14px] md:text-[16px] lg:text-[16px] 2k:text-[18px] truncate">
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Button;
